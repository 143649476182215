/* eslint-disable @next/next/no-img-element */
import { SEO } from '../../components/seo';
import AuthPageWrapper from '../../components/AuthPageWrapper';
import LoginForm from './LoginForm';

export default function Login() {
  return (
    <article>
      <SEO translationKey='seo.login' />

      <AuthPageWrapper>
        <LoginForm />
      </AuthPageWrapper>
    </article>
  );
}
